import React from 'react'

import ListContent from '../../../react4xp/components/ListContent'

import dayjs from 'dayjs';
import 'dayjs/locale/nb'

function ListContentPart({ title, type, colors, contents, link, imageShadow }) {
  return (
    <ListContent
        content={type}
        isWhite={colors.isWhite}
        boxColor={(type == 'products') ? 'white-dark' : undefined}
        section={{bgColor: colors?.bgColor, title: title}}
        howManyCols={contents.length}
        articles={type == 'articles' 
            ? contents.map(content => {
                return {
                    ...content,
                    date: content.enonicDate ? dayjs(content.enonicDate).locale('nb').format('D. MMM YYYY') : null,
                    src: content.image?.link,
                    srcMedium: content.image?.link,
                    alt: content.image?.alt,
                    redOutline: imageShadow
                }
            })
            : null}
        recipes={type == 'recipes' 
            ? contents.map(content => {
                return {
                    ...content,
                    src: content.image?.link,
                    srcMedium: content.image?.link,
                    alt: content.image?.alt,
                    redOutline: imageShadow
                }
            })
            : null}
        products={type == 'products' 
            ? contents.map(content => {
                return {
                    ...content,
                    src: content.image?.link,
                    srcMedium: content.image?.link,
                    alt: content.image?.alt,
                }
            })
            : null}
        bottomLink={{
            href: link.url?.href,
            text: link.text,
            external: link.isExternal
        }}
    />
  )
}

export default (props) => <ListContentPart {...props} />
